import React, {useState, useEffect} from "react";
import {useMutation} from "@apollo/client";
import root from "window-or-global";
import VerifyDialog from "./bootstrap/verification-modal";
import {denyVerificationMutation} from "./logic/user";
import {Button} from "react-bootstrap";
import secureImage from "../images/icons/icon_authenticator-box.svg";
import checkImage from "../images/icons/Icon awesome-check-circle.svg";

const MfaVerify = (props) => {
  const { authUrl, portalUrl, apiUrl, setMfa } = props;
  const [responded, setResponded] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [denyVerification, {loading: denyVerificationLoading}] = useMutation(denyVerificationMutation);
  
  useEffect(() => {
    const urlQuery = new URLSearchParams(root?.location?.search);
    setOtp(urlQuery.get("o"));
    setVerificationCode(urlQuery.get("v"));
  }, []);

  if (!verificationCode || !otp) {
    return (
      <div className="mfa-verify-container">
        <VerifyDialog
          onClose={()=>{console.log("close")}}
          show={true}
          hideClose={true}
          unhideHeader={false}
          size={"md"}
        >
          <div className="mfa-verify-panel">
            <h3 className="mfa-verify-heading pt-3">{"Welcome to Verification Page"}</h3>
          </div>
        </VerifyDialog>
      </div>
    );
  }

  if (responded) {
    return (
      <div className="mfa-verify-container">
        <VerifyDialog
          onClose={()=>{console.log("close")}}
          show={true}
          hideClose={true}
          size={"md"}
        >
          <div className="mfa-verify-panel responded">
          <img src={checkImage} alt="menu" className="checklogo"/>
            <h1 className="mfa-verify-heading pt-3">{"Thank you!"}</h1>
            <p className="mfa-verify-subheading">{"Thank you for your response. Your security is important."}</p>
          </div>
        </VerifyDialog>
      </div>
    );
  }

  const handleDeny = () => {
    denyVerification({
      variables: {
        requestCode: verificationCode,
      },
    }).then(() => {
      setResponded(true);
    }).catch(err => {
      setResponded(true);
    });
  };

  const handleApprove = async () => {
    const apiPath = apiUrl;

    setApproveLoading(true);
    await fetch(`${apiPath}rest.api/verify/${verificationCode}:${otp}`, {
      method: "GET",
    });

    setResponded(true);
    setApproveLoading(false);
  };

  return (
    <div className="mfa-verify-container">
      <VerifyDialog
        centered
        title={"Confirm"}
        onClose={()=>{window.location = "/login"}}
        show={true}
        unhideHeader={true}
        size={"md"}
      >
        <div className="mfa-verify-panel">
          <img src={secureImage} alt="menu" className="qrlogo"/>
          <h3 className="mfa-verify-heading">{"Approve your MFA Request"}</h3>
          <p className="mfa-verify-subheading">{"If you think that you didn't make this request, get in touch with the support team."}</p>
          <div className="mfa-verify-actions-container">
            <Button
              className="vw-button btn-deny"
              onClick={handleDeny}
              loading={denyVerificationLoading}
            >
              {"Deny"}
            </Button>
            <Button
              className="vw-button btn btn-red1"
              loading={approveLoading}
              onClick={handleApprove}
            >
              {"Approve"}
            </Button>
          </div>
        </div>
      </VerifyDialog>
    </div>
  );
};

export default MfaVerify;
